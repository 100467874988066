import cn from 'classnames';

import styles from './profile-text.module.scss';
import text from '../user-drop-container.text.json';

export const ProfileText = ({
  isNotLogged,
  fullName,
}: {
  isNotLogged: boolean;
  fullName: string;
}) => {
  const [nameFirstPart] = fullName.split(/\s+/);
  const content = isNotLogged ? text.GO_TO_LOGIN_PAGE : nameFirstPart;

  return (
    <span
      className={cn(styles.profileBoxText, isNotLogged && styles.showInMobile)}
    >
      {content}
    </span>
  );
};
